import {Answer} from './answer.model';
import {Young} from '../user/young.model';
import {Survey} from './survey.model';
import {Question} from './question.model';

export class YoungAnswer {
  id: number;
  idSurvey: number;
  idQuestion: number;
  idYoung: number;
  idAnswer: number;
  answer: string;
  dateAnswer: Date;

  survey: Survey;
  question: Question;
  answerModel: Answer;
  young: Young;
}
